
import { lazy } from 'react';
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from 'react-router-dom';
import AuthLayout from '../components/templates/layouts/AuthLayout';
import MainLayout from '../components/templates/layouts/MainLayout';
import ProtectedRoute from './ProtectedRoute';
import AuthRoute from './AuthRoute';
// import SentryTest from '../pages/sentry-test';


const ScheduleCataloguePage = lazy(() => import('../pages/schedule-catalogue'));
const AuctionSchedulePage = lazy(() => import('../pages/auction-schedule'));
const FavoritePage = lazy(() => import('../pages/profile/favorite'));
const NiplPage = lazy(() => import('../pages/profile/nipl'));
const NotificationPage = lazy(() => import('../pages/profile/notification'));
const ConsignmentAuctionPage = lazy(() => import('../pages/consignment-auction'));
const ProfileTransaction = lazy(() => import('../pages/profile/transaction'));
const ProfileTransactionUnitDetail = lazy(() => import('../pages/profile/transaction/transaction-unit-detail'));
const ProfileSettingPage = lazy(() => import('../pages/profile/setting'));
const ProfileProxyBid = lazy(() => import('../pages/profile/proxy-bid'));
const ProfileAuctionHistory = lazy(() => import('../pages/profile/auction-history'));
const PremiumAuction = lazy(() => import('../pages/premium-auction'));
const BuyNiplPage = lazy(() => import('../pages/buy-nipl'));
const ForgotPasswordPage = lazy(() => import('../pages/forgot-password'));
const ResetPasswordPage = lazy(() => import('../pages/reset-password'));
const RegisterPage = lazy(() => import('../pages/register'));
const HomePage = lazy(() => import('../pages/home'));
const UnitDetailPage = lazy(() => import('../pages/unit-detail'));
const LiveAuctionPage = lazy(() => import('../pages/live-auction'));
const TimeAuctionPage = lazy(() => import('../pages/time-auction'));
// const OldTimeAuctionPage = lazy(() => import('../pages/time-auction/index-old'));
const OfflineAuctionPage = lazy(() => import('../pages/offline-auction'));
const ProcedurePage = lazy(() => import('../pages/procedure'))
const PrivacyPolicyPage = lazy(() => import('../pages/privacy-policy'))
const TermsAndConditionsPage = lazy(() => import('../pages/terms-and-conditions'))
const TermsAndConditionsTimeAuctionPage = lazy(() => import('../pages/terms-and-conditions-time-auctioned'))
const OurLocationsPage = lazy(() => import('../pages/our-locations'))
const BlogListPage = lazy(() => import('../pages/blog-list'))
const FAQPage = lazy(() => import('../pages/faq'))
const BlogDetailPage = lazy(() => import('../pages/blog-detail'))
const ContactUsPage = lazy(() => import('../pages/contact-us'))
const LoginPage = lazy(() => import('../pages/login'))
const AboutUsPage = lazy(() => import('../pages/about-us'))

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <ProtectedRoute>
        <MainLayout />
      </ProtectedRoute>
    ),
    children: [
      {
        path: '/about-us',
        element: <AboutUsPage />,
      },
      {
        path: '/contact-us',
        element: <ContactUsPage />,
      },
      {
        path: '/faq',
        element: <FAQPage />,
      },
      {
        path: '/blogs/:blog_id',
        element: <BlogDetailPage />,
      },
      {
        path: '/blogs',
        element: <BlogListPage />,
      },
      {
        path: '/our-locations',
        element: <OurLocationsPage />,
      },
      {
        path: '/terms-and-conditions',
        element: <TermsAndConditionsPage />,
      },
      {
        path: '/terms-and-conditions-time-auctioned',
        element: <TermsAndConditionsTimeAuctionPage />,
      },
      {
        path: '/privacy-policy',
        element: <PrivacyPolicyPage />,
      },
      {
        path: '/procedure',
        element: <ProcedurePage />,
      },
      {
        path: "/profile/setting",
        element: <ProfileSettingPage />,
      },
      {
        path: '/schedule-catalogue',
        element: <ScheduleCataloguePage />,
      },
      {
        path: '/auction-schedule',
        element: <AuctionSchedulePage />,
      },
      {
        path: '/profile/favorite',
        element: <FavoritePage />,
      },
      {
        path: "/profile/nipl",
        element: <NiplPage />,
      },
      {
        path: "/profile/notification",
        element: <NotificationPage />,
      },
      {
        path: "/profile/transaction",
        element: <ProfileTransaction />,
      },
      {
        path: "/profile/auction-history",
        element: <ProfileAuctionHistory />,
      },
      {
        path: "/profile/transaction/:unit_id",
        element: <ProfileTransactionUnitDetail />,
      },
      {
        path: '/profile/proxy-bid',
        element: <ProfileProxyBid />,
      },
      {
        path: '/consignment-auction',
        element: <ConsignmentAuctionPage />,
      },
      {
        path: '/premium-auction',
        element: <PremiumAuction />,
      },
      {
        path: '/buy-nipl',
        element: <BuyNiplPage />,
      },
      {
        path: "/",
        element: <HomePage />,
      },
      {
        path: "/auction-unit/:unit_id",
        element: <UnitDetailPage />,
      },
      {
        path: "/live-auction/:auction_id",
        element: <LiveAuctionPage />,
      },
      {
        path: "/time-auction/:auction_id/:product_id",
        element: <TimeAuctionPage />,
      },
      // {
      //   path: "/new-time-auction",
      //   element: <OldTimeAuctionPage />,
      // },
      {
        path: "/offline-auction/:auction_id",
        element: <OfflineAuctionPage />,
      },
      {
        path: "*",
        element: <Navigate to="/" />,
      },
    ],
  },
  {
    path: "/",
    element: (
      <AuthRoute>
        <AuthLayout />
      </AuthRoute>
    ),
    children: [
      {
        path: "/login",
        element: <LoginPage />,
      },
      {
        path: "/register",
        element: <RegisterPage />,
      },
      {
        path: "/forgot-password",
        element: <ForgotPasswordPage />,
      },
      {
        path: "/reset-password",
        element: <ResetPasswordPage />,
      },
    ],
  },
  // {
  //   path: "/page-sentry-tes",
  //   element: <SentryTest />
  // }
]);

const RouterApp = () => {
  return <RouterProvider router={router} />;
};

export default RouterApp;